import React, { useEffect, useContext, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { ID_DOCUMENT_OPTIONS, userRoles } from 'constants/index';
import { uploadFile } from 'utility/activeStorage';
import { HookDropZone, AddedFile } from 'components/uploader/HookDropzone';
import { SubmitButton, TextField, ErrorLabel, Checkbox, Select, PhoneField } from 'components/FormFields/v2';
import { AddUSBankAccountContext } from './AddUSBankAccountContext';
import { AuthContext } from 'context/Auth';
import { SOLID_DESIGNATION_ROLES } from 'graphql/wallets';
import passportExampleImage from 'images/passport_example.png';

const arePhoneNumbersUnique = (array) => {
  const phoneNumbers = array.map((item) => item.phoneNumber);
  return new Set(phoneNumbers).size === array.length;
};

const MINIMAL_RELEVANT_OWNERSHIP_PERCENTAGE = 25;

const Identification = ({ contacts, corporateShareholders, onNextStep, onPreviousStep, loading, error }) => {
  const { bankAccountInfo, setBankAccountInfo } = useContext(AddUSBankAccountContext);

  const ownersWithOverMinimalOwnership = useMemo(
    () =>
      [...contacts, ...corporateShareholders].filter(
        (owner) => owner.ownership >= MINIMAL_RELEVANT_OWNERSHIP_PERCENTAGE
      ),
    [contacts, corporateShareholders]
  );

  const accountOwners = useMemo(
    () => [...contacts].filter((owner) => owner.roles.includes(userRoles.ACCOUNT_OWNER)),
    [contacts]
  );
  const relevantOwners = useMemo(
    () => (ownersWithOverMinimalOwnership.length ? ownersWithOverMinimalOwnership : accountOwners),
    [ownersWithOverMinimalOwnership, accountOwners]
  );

  const { data: solidDesignationRolesData, loading: solidDesignationRolesLoading } = useQuery(SOLID_DESIGNATION_ROLES);
  const solidDesignationRoleOptions = useMemo(
    () => get(solidDesignationRolesData, 'solidDesignationRoles', []),
    [solidDesignationRolesData]
  );

  const initialValues = {
    members: relevantOwners.map((member) => ({ phoneNumber: member.phone })),
  };
  const form = useForm({ defaultValues: initialValues });
  const { me, meLoading } = useContext(AuthContext);
  const { firstName, lastName } = me || {};
  const { handleSubmit, setValue, watch, register } = form;
  const [filesMap, setFilesMap] = useState({});
  const [validationError, setValidationError] = useState();
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const currentMembers = watch('members');
  const hasDocumentsForMembers =
    currentMembers && currentMembers.length > 0 && Object.keys(filesMap).length === currentMembers.length;
  const isAcceptedTerms = watch('acceptedTerms');
  const hasUniquePhoneNumberForMembers = arePhoneNumbersUnique(currentMembers);
  const controlPersonsCount = currentMembers?.filter((member) => member.controlPerson)?.length || 0;

  useEffect(() => {
    const fillFormWithPreviousValues = () => {
      const { members } = bankAccountInfo;

      setValue('members', members);
    };

    if (bankAccountInfo) {
      fillFormWithPreviousValues();
    }
  }, [bankAccountInfo]);

  const onSubmit = async ({ members }) => {
    if (!hasDocumentsForMembers) {
      return setValidationError({ type: 'manual', message: 'Members identification is required' });
    }

    if (!controlPersonsCount) {
      return setValidationError({ type: 'manual', message: 'At least one control person is required' });
    }

    if (controlPersonsCount > 1) {
      return setValidationError({ type: 'manual', message: 'Only one contact might be a control person' });
    }

    if (!hasUniquePhoneNumberForMembers) {
      return setValidationError({ type: 'manual', message: 'Members must have unique phone numbers' });
    }
    uploadFilesAndCreateAccount({ members });
  };

  const uploadFilesAndCreateAccount = async ({ members }) => {
    setValidationError(undefined);

    const updatedMembers = [...members];

    setUploadingFiles(true);
    try {
      for (const [index, file] of Object.entries(filesMap)) {
        const blob = await uploadFile(file);

        const blobSignedId = blob.signed_id;
        updatedMembers[index] = { ...updatedMembers[index], blobSignedId };
      }

      await handleUpdateBankInfo({ ...bankAccountInfo, members: updatedMembers });
      setUploadingFiles(false);
    } catch (err) {
      console.error(err);
      setValidationError({ type: 'manual', message: 'Failed to upload documents.' });
      setUploadingFiles(false);
    }
  };

  const handleUpdateBankInfo = async (newBankInfo) => {
    setBankAccountInfo(newBankInfo);
    await onNextStep(newBankInfo);
  };

  return (
    <div>
      <FormProvider {...form} graphQLErrors={Array.isArray(error) && error}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-max-h-screen tw-overflow-y-scroll">
            <div className="tw-px-8 tw-text-sm tw-text-neutral-grey-2">
              <div className="tw-text-lg tw-py-2 tw-font-bold sm:tw-visible lg:tw-invisible"> Ownership </div>
              To open a USD bank account we require you to verify and provide passport identification of all persons who
              directly or indirectly own {MINIMAL_RELEVANT_OWNERSHIP_PERCENTAGE}% or more of the business.
            </div>
            <div className="tw-px-8 tw-mt-2 tw-text-sm tw-text-neutral-grey-2">
              Passports must be valid for at least 6 months and photos must include both the picture and signature
              pages. Providing all the required documents (in the right format) will help ensure a smooth and successful
              account opening process.{' '}
              <a href={passportExampleImage} target="_blank" rel="noreferrer">
                <span className="tw-underline">Example Photo</span>
              </a>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-4 tw-mt-8 tw-px-8 tw-mb-2">
              {relevantOwners.map((owner, index) => {
                const onChangeFile = (file) => setFilesMap({ ...filesMap, [index]: file });

                return (
                  <OwnerInformationSection
                    key={owner.id}
                    index={index}
                    owner={owner}
                    file={filesMap[index]}
                    onChangeFile={onChangeFile}
                    solidDesignationRoleOptions={solidDesignationRoleOptions}
                    solidDesignationRolesLoading={solidDesignationRolesLoading}
                  />
                );
              })}
            </div>
          </div>
          {validationError && (
            <div className="tw-my-4">
              <ErrorLabel className="tw-px-8" error={validationError} />
            </div>
          )}
          <div className="tw-px-8 tw-mt-4 tw-mb-6">
            <Checkbox
              label={
                <div className="tw-cursor-pointer tw-text-sm">
                  <span className="tw-font-semibold">
                    I, {!meLoading && `${firstName} ${lastName}`}, hereby certify
                  </span>{' '}
                  to the best of my knowledge, that the information provided is complete and correct.
                </div>
              }
              name="acceptedTerms"
              ref={register({ required: true })}
            />
          </div>
          {error && !Array.isArray(error) && (
            <div className="tw-py-4">
              <ErrorLabel
                className="tw-px-8"
                error={error.message ? error : { message: 'Something went wrong. Please try again later.' }}
              />
            </div>
          )}
          <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between">
            <button
              onClick={onPreviousStep}
              className="tw-bg-neutral-light tw-border tw-border-neutral-grey-3 tw-py-2 tw-px-4 tw-rounded-md tw-text-center tw-mb-4 lg:tw-mb-0 tw-w-full lg:tw-w-32"
            >
              Back
            </button>
            <SubmitButton
              disabled={!hasDocumentsForMembers || uploadingFiles || !isAcceptedTerms || loading}
              className="tw-w-full lg:tw-w-32"
            >
              {uploadingFiles || loading ? 'Submitting...' : 'Next'}
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const OwnerInformationSection = ({
  owner,
  index,
  file,
  onChangeFile,
  solidDesignationRoleOptions,
  solidDesignationRolesLoading,
}) => {
  const { register } = useFormContext();
  const name = owner.name || `${owner.firstName} ${owner.lastName}`;

  const handleFilesAdded = (files) => {
    if (files && files.length > 0) onChangeFile(files[0]);
  };
  const handleRemoveFile = () => onChangeFile(null);

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
      <div className="tw-font-bold tw-w-1/2">{name}</div>
      <input
        name={`members[${index}].salesforceContactId`}
        value={owner.id}
        type="hidden"
        ref={register({ required: true })}
      />
      <PhoneField
        name={`members[${index}].phoneNumber`}
        label="Phone Number"
        required
        autoComplete="tel-national"
        rules={{ required: true }}
      />

      <div>
        <div className="tw-flex tw-gap-2 tw-text-sm tw-text-neutral-grey-1 tw-mb-1">
          <label htmlFor={`members[${index}].documentType`} className="tw-w-1/3 tw-mb-0">
            Document Type<span className="tw-text-semantic-error">&nbsp;*</span>
          </label>
          <label htmlFor={`members[${index}].documentNumber`} className="tw-w-1/3 tw-mb-0">
            Document Number<span className="tw-text-semantic-error">&nbsp;*</span>
          </label>
          <div className="tw-w-1/3">
            Photo or scan of the document<span className="tw-text-semantic-error">&nbsp;*</span>
          </div>
        </div>
        <div className="tw-flex tw-gap-2">
          <div className="tw-w-1/3">
            <Select
              name={`members[${index}].documentType`}
              options={ID_DOCUMENT_OPTIONS}
              placeholder="Choose"
              ref={register({ required: true })}
              required
            />
          </div>
          <div className="tw-w-1/3">
            <TextField
              name={`members[${index}].documentNumber`}
              placeholder="123456789"
              ref={register({ required: true })}
              required
            />
          </div>
          <div className="tw-w-1/3">
            {file ? (
              <AddedFile file={file} onRemoveFile={handleRemoveFile} size="sm" />
            ) : (
              <HookDropZone onFilesAdded={handleFilesAdded} size="sm" />
            )}
          </div>
        </div>
      </div>

      <div className="tw-flex tw-flex-col tw-gap-2">
        <label htmlFor={`members[${index}].designatedRole`} className="tw-text-sm tw-text-neutral-grey-1 tw-mb-0">
          Designated Role<span className="tw-text-semantic-error"> *</span>
        </label>
        <div className="tw-flex tw-gap-2 tw-items-center">
          <div className="tw-w-1/3">
            <Checkbox
              label={<div className="tw-text-sm">Is Control Person</div>}
              name={`members[${index}].controlPerson`}
              ref={register()}
            />
          </div>
          <div className="tw-w-2/3">
            {solidDesignationRolesLoading ? (
              <div className="tw-flex tw-animate-pulse tw-bg-neutral-grey-4 tw-h-11 tw-w-full tw-rounded" />
            ) : (
              <Select
                name={`members[${index}].designatedRole`}
                options={solidDesignationRoleOptions}
                placeholder="Choose role"
                ref={register({ required: true })}
                required
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Identification;
