import React, { useEffect, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import config from 'config';
import { SubmitButton, TextField, Checkbox, DatePicker } from 'components/FormFields/v2';
import { AddUSBankAccountContext } from './AddUSBankAccountContext';

const Details = ({ onNextStep }) => {
  const { bankAccountInfo, setBankAccountInfo } = useContext(AddUSBankAccountContext);
  const form = useForm();
  const { register, handleSubmit, setValue, watch } = form;
  const acceptedTerms = watch('acceptedTerms');

  useEffect(() => {
    const fillFormWithPreviousValues = () => {
      const { craBusinessNumber } = bankAccountInfo;

      setValue('craBusinessNumber', craBusinessNumber);
      setValue('acceptedTerms', true);
    };

    if (bankAccountInfo) {
      fillFormWithPreviousValues();
    }
  }, [bankAccountInfo]);

  const onSubmit = (data) => {
    const { craBusinessNumber, formationDate } = data;

    setBankAccountInfo({ craBusinessNumber, formationDate });
    onNextStep();
  };

  return (
    <div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="tw-px-8">
              <div className="tw-text-neutral-grey-2 tw-text-lg tw-py-2 tw-font-bold sm:tw-visible lg:tw-invisible">
                {' '}
                Details{' '}
              </div>
              <small className="tw-text-neutral-grey-2">
                In order to open a US Dollar Bank Account with Loop, we'll need you to complete this brief onboarding
                and provide some additional information about your business.
              </small>
            </div>
            <div className="tw-p-8 tw-space-y-8">
              <div>
                <TextField
                  ref={register({
                    required: true,
                    pattern: { value: /\d{9}/, message: 'Must contain exactly 9 digits' },
                  })}
                  name="craBusinessNumber"
                  label="Business Number"
                  placeholder="123456789"
                  maxLength="9"
                  rootClass="tw-flex-grow"
                  required
                  data-private
                />
                <span className="tw-text-xs tw-text-neutral-grey-2 tw-leading-none">
                  The business number (BN) is a 9-digit account number that identifies your business to federal,
                  provincial, and municipal governments. The BN is issued by the Canada Revenue Agency (CRA).
                </span>
              </div>
              <div>
                <DatePicker
                  name="formationDate"
                  label="Formation Date"
                  placeholder="DD/MM/YYYY"
                  ref={register({ required: true })}
                  required
                />
                <span className="tw-text-xs tw-text-neutral-grey-2 tw-leading-none">
                  The date your business was registered with the government.
                </span>
              </div>
              <div>
                <small className="tw-text-neutral-grey-2">
                  By agreeing to the{' '}
                  <a target="_blank" href={config.electronicsCommunicationAgreement} rel="noopener noreferrer">
                    <span className="tw-text-neutral-grey-1 tw-underline">Electronic Communications Agreement</span>
                  </a>
                  , you consent to receive electronic disclosures and/or statements for your Loop US Dollar account and
                  consent to be legally bound to this agreement.
                </small>
              </div>
              <Checkbox
                label={
                  <div className="tw-flex tw-cursor-pointer">
                    <small>
                      I have read and accept the{' '}
                      <a target="_blank" href={config.electronicsCommunicationAgreement} rel="noopener noreferrer">
                        <span className="tw-text-primary-dark-green tw-underline">
                          Electronic Communications agreement.
                        </span>
                      </a>
                    </small>
                  </div>
                }
                name="acceptedTerms"
                ref={register({ required: true })}
              />
            </div>
          </div>
          <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex lg:tw-justify-end">
            <SubmitButton disabled={!acceptedTerms} className="tw-w-full lg:tw-w-32">
              Next
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Details;
