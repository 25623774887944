import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';

import { User } from 'types/user';
import { SolidMigrationModalProps } from '../SolidMigrationModal.types';
import { AuthContext } from 'context/Auth';
import { loadFromLocalStorage, saveToLocalStorage } from 'utility/localStorage';

const useSolidMigrationModal = ({ shouldShowSolidModal }: SolidMigrationModalProps) => {
  const history = useHistory();
  const { me } = useContext(AuthContext) as unknown as {
    me: User;
  };

  const newSolidAccountNumber = get(me, 'account.newSolidAccountNumber', false);

  const showModal =
    newSolidAccountNumber && loadFromLocalStorage<boolean>('solidMigrationModalAlreadyShown', true)
      ? false
      : shouldShowSolidModal;

  const [show, setShow] = useState(showModal);
  const onNavigateToBankAccounts = () => {
    onClose();
    history.push('/dashboard/accounts');
  };

  const onClose = () => {
    setShow(false);
    saveToLocalStorage('solidMigrationModalAlreadyShown', true);
  };

  return {
    onNavigateToBankAccounts,
    show,
    onClose,
    newSolidAccountNumber,
  };
};

export default useSolidMigrationModal;
