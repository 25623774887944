import React, { useState, useMemo } from 'react';
import _ from 'lodash';

import { SCOPE, PAYEE_RECORD_TYPES } from 'constants/index';
import PayPayee from 'components/payments/PayPayee';
import Button from 'components/Button';
import { Modal } from 'components/UI';
import { PreviewContent } from 'components/OnboardingDashboard/components/PreviewScreen/components';
import PermissionChecker from 'components/PermissionChecker';
import useIsContactPermission from 'hooks/useIsContactPermission';
import { useToggle, useGetUserInfo } from 'hooks';
import SupplierItem from './SupplierItem';
import CreditCardPayeeItem from './CreditCardPayeeItem';
import CRAAccountItem from './CRAAccountItem';

const PayeesList = ({ payees: visiblePayees = [], lineOfCredit, bankAccounts, wallets, payeeCount, onAddPayee }) => {
  const { createPayee } = useIsContactPermission();

  if (payeeCount === 0) return <EmptyState onAddPayee={onAddPayee} createPayee={createPayee} />;

  if (!visiblePayees?.length) return <NoVisiblePayees />;

  const groups = useMemo(
    () =>
      _.groupBy(visiblePayees, (p) => (!p.record.displayName ? p.record.name : p.record.displayName)[0].toUpperCase()),
    [visiblePayees]
  );

  const [payeeId, setPayeeId] = useState();
  const { isKYCApproved } = useGetUserInfo();

  const { isOpen: showPayPayeeModal, open: openPayPayeeModal, close: onClosePayPayeeModal } = useToggle();

  const {
    isOpen: showOnboardingPreviewModal,
    open: openOnboardingPreviewModal,
    close: onCloseOnboardingPreviewModal,
  } = useToggle();

  const onShowPayPayeeModal = (id) => {
    setPayeeId(id);
    openPayPayeeModal();
  };

  const onPayPayee = isKYCApproved ? onShowPayPayeeModal : openOnboardingPreviewModal;

  return (
    <div>
      {Object.keys(groups)
        .sort((a, b) => a.localeCompare(b))
        .map((key) => (
          <div key={key}>
            <div className="tw-bg-neutral-light lg:tw-bg-neutral-grey-4 tw-px-4 tw-py-2 tw-rounded tw-mt-4">{key}</div>
            {groups[key].map((payee) => {
              return <PayeeListItem key={payee.id} payee={payee} onPayPayee={onPayPayee} />;
            })}
          </div>
        ))}
      <PayPayeeModal
        wallets={wallets}
        bankAccounts={bankAccounts}
        lineOfCredit={lineOfCredit}
        payees={visiblePayees}
        show={showPayPayeeModal}
        onClose={onClosePayPayeeModal}
        initialPayeeId={payeeId}
      />
      <Modal show={showOnboardingPreviewModal} onClose={onCloseOnboardingPreviewModal}>
        <PreviewContent />
      </Modal>
    </div>
  );
};

const EmptyState = ({ onAddPayee, createPayee }) => (
  <div className="tw-flex tw-items-center">
    <p className="tw-mr-1">You have no payees</p>
    <PermissionChecker scope={SCOPE.managePayments} memberScope={createPayee}>
      <Button onClick={onAddPayee}>
        <small className="tw-text-primary-dark-green tw-font-semibold">Add a payee</small>
      </Button>
    </PermissionChecker>
  </div>
);

const NoVisiblePayees = () => (
  <div className="tw-flex">
    <p className="tw-mr-1">No payees were found for the given search filters.</p>
  </div>
);

const PayeeListItem = (props) => {
  const { payee } = props;
  if (!payee) return null;

  switch (payee.record.__typename) {
    case PAYEE_RECORD_TYPES.Supplier:
      return <SupplierItem {...props} />;
    case PAYEE_RECORD_TYPES.CreditCardPayee:
      return <CreditCardPayeeItem {...props} />;
    case PAYEE_RECORD_TYPES.CRAAccount:
      return <CRAAccountItem {...props} />;
    default:
      return null;
  }
};

export const PayPayeeModal = ({
  payees,
  lineOfCredit,
  wallets,
  show,
  onClose,
  initialPayeeId,
  bankAccounts,
  source,
  reimbursement,
}) => {
  return (
    <Modal show={show} onClose={onClose} title="Pay Payee">
      <div className="tw-flex tw-flex-col tw-py-4">
        <PayPayee
          bankAccounts={bankAccounts}
          wallets={wallets}
          lineOfCredit={lineOfCredit}
          payees={payees}
          onFinish={onClose}
          initialPayeeId={initialPayeeId}
          source={source}
          reimbursement={reimbursement}
        />
      </div>
    </Modal>
  );
};

export default PayeesList;
