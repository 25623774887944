import React from 'react';
import cx from 'classnames';

import { StepAndConnector } from './components';
import { ProgressProps } from './Progress.types';
import { useIsMobile } from 'hooks';
const Progress = ({
  currentStepIndex,
  stepLabelsAndIndexes,
  stepLabelsContent,
  errorSteps,
  isCompleted,
  classNames = 'tw-py-2 tw-px-8',
  isVertical = false,
}: ProgressProps) => {
  const stepsEntries = Object.entries(stepLabelsAndIndexes);

  return (
    <div
      className={cx(
        'tw-flex tw-relative tw-z-0',
        isVertical ? 'tw-flex-col' : 'tw-flex-row tw-items-start',
        useIsMobile() ? 'tw-h-12' : '',
        classNames
      )}
    >
      {stepsEntries.map(([label, stepIndexes], index) => {
        const hasError = !!errorSteps?.includes(stepIndexes[0]);

        return (
          <StepAndConnector
            key={label}
            currentStep={currentStepIndex}
            steps={stepIndexes}
            label={label}
            labelContent={stepLabelsContent?.[label]}
            hasError={hasError}
            isVertical={isVertical}
            isCompleted={isCompleted}
            isFirstStep={index === 0}
            isLastStep={index === stepsEntries.length - 1}
          />
        );
      })}
    </div>
  );
};

export default Progress;
