import React, { useContext } from 'react';
import cx from 'classnames';
import { get } from 'lodash';

import { AuthContext } from 'context/Auth';
import { SCOPE } from 'constants/index';
import CreditCard from 'domain/creditCard';
import Loader from 'components/cards/Loader';
import { Header } from '../Header';
import RecentTransactions from 'components/RecentTransactions';
import CreditCardsSummary from 'components/home/desktop/CreditCardsSummary';
import LineOfCreditSummary from 'components/home/desktop/LineOfCreditSummary';
import PaymentsSummary from 'components/home/desktop/PaymentsSummary/PaymentsSummary';
import AccountsSummary from 'components/home/desktop/AccountsSummary';
import DelinquentPaymentBanner from 'components/home/DelinquentPaymentBanner';
import ConnectBankAccountModal from 'components/home/ConnectBankAccountModal';
import PendingAccountDepositsTable from 'components/home/desktop/PendingAccountDepositsTable';
import PermissionChecker from 'components/PermissionChecker';
import { ProductCommunicationCards } from './ProductCommunicationCards';
import { SolidMigrationModal } from './SolidMigrationModal';
import { TimedComponentWrapper } from 'components/UI/TimedComponentWrapper';
import { SolidMigrationBanner } from './SolidMigrationBanner';

const DesktopLayout = (props) => {
  const {
    globalCreditLimit,
    wallets,
    creditCards,
    creditCardIds,
    groupedCardsInfo,
    hasLineOfCredit,
    lineOfCredit,
    transactions,
    loadingTransactions,
    hasAtLeastOneBankAccountVerified,
    connectedBankAccount,
    bankAccountVerified,
    bankAccountsLoading,
    creditCardLoading,
    walletLoading,
    globalCreditLimitLoading,
    allowCardCreation,
    loadingCreditInfo,
    loadingProducts,
    accountCompletedEvents,
    loadingCompletedEvents,
  } = props;

  const accountNames = wallets.map((wallet) => wallet.displayName);
  const { me, meLoading } = useContext(AuthContext);

  const { welcomeModalSeen, newSolidAccountNumberSeen } = get(me, 'account');

  return (
    <div className="tw-w-full tw-pb-16">
      <Header />

      {!newSolidAccountNumberSeen && <SolidMigrationBanner />}

      <div className="tw-mt-10 tw-flex tw-flex-col">
        <div>{!!groupedCardsInfo && <DelinquentPaymentBanner primaryCard={new CreditCard(groupedCardsInfo)} />}</div>
        <CreditCardsSummary
          creditCardLoading={creditCardLoading}
          creditCards={creditCards}
          creditCardIds={creditCardIds}
          groupedCardsInfo={groupedCardsInfo}
          globalCreditLimit={globalCreditLimit}
          globalCreditLimitLoading={globalCreditLimitLoading}
          hasAtLeastOneBankAccountVerified={hasAtLeastOneBankAccountVerified}
          connectedBankAccount={connectedBankAccount}
          bankAccountVerified={bankAccountVerified}
          bankAccountsLoading={bankAccountsLoading}
          hasLineOfCredit={hasLineOfCredit}
          allowCardCreation={allowCardCreation}
        />
        <div>
          {hasLineOfCredit && (
            <div className="tw-mt-10">
              <LineOfCreditSummary
                loadingCreditInfo={loadingCreditInfo}
                hasLineOfCredit={hasLineOfCredit}
                lineOfCredit={lineOfCredit}
              />
            </div>
          )}
        </div>

        <div className={cx(accountCompletedEvents.length >= 2 && 'tw-order-last ', 'tw-mt-12')}>
          <ProductCommunicationCards
            accountCompletedEvents={accountCompletedEvents}
            loadingCompletedEvents={loadingCompletedEvents}
          />
        </div>
        <div className="tw-mt-10">
          <AccountsSummary wallets={wallets} walletLoading={walletLoading} />
        </div>
        <div className="tw-mt-10">
          <PermissionChecker scope={SCOPE.managePayments}>
            <PaymentsSummary lineOfCredit={lineOfCredit} wallets={wallets} loadingProducts={loadingProducts} />
          </PermissionChecker>
        </div>
        <div>
          <div className="tw-mt-16">
            <PendingAccountDepositsTable
              transactions={transactions}
              accountNames={accountNames}
              loadingTransactions={loadingTransactions}
            />
          </div>
        </div>
        <div>
          <div className="tw-mt-16">
            {loadingTransactions ? (
              <div data-testid="loader-transactions">
                <Loader />
              </div>
            ) : (
              <RecentTransactions transactions={transactions} accountNames={accountNames} includePayments={false} />
            )}
          </div>
        </div>
      </div>

      {!meLoading && !newSolidAccountNumberSeen && (
        <TimedComponentWrapper startAt="2024-09-30" endAt="2024-10-04">
          <SolidMigrationModal shouldShowSolidModal={!newSolidAccountNumberSeen} />
        </TimedComponentWrapper>
      )}

      {!walletLoading && !meLoading && !connectedBankAccount && (
        <ConnectBankAccountModal welcomeModalSeen={welcomeModalSeen} />
      )}
    </div>
  );
};

export default DesktopLayout;
