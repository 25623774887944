import React from 'react';
import config from 'config';

import { Modal } from 'components/UI';
import { SolidMigrationModalProps } from './SolidMigrationModal.types';
import Button from 'components/Button';
import Href from 'components/UI/Href';
import useSolidMigrationModal from './hooks/useSolidMigrationModalHook';

const SolidMigrationModal = ({ shouldShowSolidModal }: SolidMigrationModalProps) => {
  const { show, onClose, onNavigateToBankAccounts, newSolidAccountNumber } = useSolidMigrationModal({
    shouldShowSolidModal,
  });

  return (
    <Modal show={show} onClose={onClose} fitContent={true} title="Important Update About Your USD Account">
      <div className="tw-mx-8 tw-my-8 tw-min-w-96">
        <div className="content tw-mb-8 tw-flex tw-flex-col tw-gap-4">
          <p>
            As part of our continuous efforts to provide you with the best possible financial technology infrastructure,
            we are making some important updates and as a result, your Loop USD account number has been updated.
          </p>
          <div>
            <b className="tw-mb-4 tw-block">Key Details</b>
            <ul className="tw-list-disc tw-ml-6">
              <li>
                <strong>Effective: September 30th, 2024</strong>, your new account number will be live. Any payments
                using your old account number after that date will be automatically redirected to your new account for
                30 days.
              </li>
              <li>
                <strong>New Account Number: {newSolidAccountNumber} </strong>
              </li>
              <li>
                <strong>Routing Number:</strong> No change ({config.newUsRoutingNumber})
              </li>
            </ul>
          </div>
          <p>
            Once your new account number is live on <strong>September 30th</strong>, you can view it by clicking{' '}
            <strong>Accounts</strong> &#62; <strong>Select your USD Account </strong> &#62;{' '}
            <strong>Bank Details</strong>.
          </p>
          <p className="tw-inline">
            We value your continued partnership and as always, we are available to address any questions you may have
            regarding this change. Please contact us at
            <Href target="_blank" rel="noopener noreferrer" href={`mailto:${config.supportEmail}`}>
              <span className="tw-text-neutral-dark tw-text-base">{config.supportEmail}</span>
            </Href>
          </p>
        </div>

        <Button primary onClick={onNavigateToBankAccounts}>
          View your updated bank details
        </Button>
      </div>
    </Modal>
  );
};

export default SolidMigrationModal;
